import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import elephant from "../../images/topic-3/section-6/elephant-3.jpg"
import logisticModel from "../../images/topic-3/section-6/logistic-model.svg"
import recordedData from "../../images/topic-3/section-6/recorded-data.svg"
import logisticModel_fr from "../../images/topic-3/section-6/logistic-model_fr.svg"
import recordedData_fr from "../../images/topic-3/section-6/recorded-data_fr.svg"
import question1a from "../../images/topic-3/section-6/question-1a.svg"
import question1b from "../../images/topic-3/section-6/question-1b.svg"
import question1c from "../../images/topic-3/section-6/question-1c.svg"
import question1aSel from "../../images/topic-3/section-6/question-1a-selected.svg"
import question1bSel from "../../images/topic-3/section-6/question-1b-selected.svg"
import question1cSel from "../../images/topic-3/section-6/question-1c-selected.svg"
import question2a from "../../images/topic-3/section-6/question-2a.svg"
import question2b from "../../images/topic-3/section-6/question-2b.svg"
import question2c from "../../images/topic-3/section-6/question-2c.svg"
import question2aSel from "../../images/topic-3/section-6/question-2a-selected.svg"
import question2bSel from "../../images/topic-3/section-6/question-2b-selected.svg"
import question2cSel from "../../images/topic-3/section-6/question-2c-selected.svg"
import BottomNav from "../../components/bottomNav"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import MyBreadcrumb from "../../components/myBreadcrumb"
import MyModal from "../../components/mathModal"
import RespMsg from "../../components/respMsg"
import parse from "html-react-parser"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const EPopGrowthSection6 = ({ location }) => {
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation()
  const [changeGraph, setChangeGraph] = useState(false)

  const pageContent = {
    breadcrumb: [
      parse(t("Topic3_Section6_breadcrumb[1][0]")),
      parse(t("Topic3_Section6_breadcrumb[1][1]")),
      parse(t("Topic3_Section6_breadcrumb[1][2]")),
      parse(t("Topic3_Section6_breadcrumb[1][3]")),
    ],
    title: [
      parse(t("Topic3_Section6_title[0]")),
      parse(t("Topic3_Section6_title[1]")),
      parse(t("Topic3_Section6_title[2]")),
      parse(t("Topic3_Section6_title[3]")),
    ],
    topText: [
      <span>
        {parse(t("Topic3_Section6_topText[0]"))}
        <span
          className={changeGraph ? "" : "linkMeNoColor"}
          onClick={() => setChangeGraph(true)}
        >
          {parse(t("Topic3_Section3_instructions.here"))}
        </span>
        .
      </span>,
      parse(t("Topic3_Section6_topText[1]")),
      parse(t("Topic3_Section6_topText[2]")),
      parse(t("Topic3_Section6_topText[3]")),
    ],
    imageText: [
      <span>
        {parse(t("Topic3_Section6_imgCaption"))}
        <a href={parse(t("Topic3_Section6_imgLink"))} target="_blank">
          ({parse(t("Topic3_Section6_imgLink"))})
        </a>
        .
      </span>,
      "",
      "",
      "",
    ],
    middleTexts: [
      parse(t("Topic3_Section6_middleTexts[0]")),
      parse(t("Topic3_Section6_middleTexts[1]")),
      parse(t("Topic3_Section6_middleTexts[2]")),
      parse(t("Topic3_Section6_middleTexts[3]")),
    ],
    bottomTexts: [
      parse(t("Topic3_Section6_bottomTexts[0]")),
      parse(t("Topic3_Section6_bottomTexts[1]")),
      parse(t("Topic3_Section6_bottomTexts[2]")),
      <strong>
        {parse(t("Topic3_Section6_bottomTexts[3][0]"))}
        <Link className="linkMeNoColor" to="/">
          {parse(t("Topic3_Section3_instructions.here"))}
        </Link>{" "}
        {parse(t("Topic3_Section6_bottomTexts[3][1]"))}
      </strong>,
    ],
    instructions: [
      parse(t("Topic3_Section6_instructions[0]")),
      parse(t("Topic3_Section6_instructions[1]")),
      parse(t("Topic3_Section6_instructions[2]")),
    ],
    imgs: [
      ["", "", ""],
      [question1a, question1b, question1c],
      [question2a, question2b, question2c],
      ["", "", ""],
    ],
    imgsSel: [
      ["", "", ""],
      [question1aSel, question1bSel, question1cSel],
      [question2aSel, question2bSel, question2cSel],
      ["", "", ""],
    ],
    imgsAlt: [
      ["", "", ""],
      [
        parse(t("Topic3_Section6_imgsAlt[1][0].alt")),
        parse(t("Topic3_Section6_imgsAlt[1][1].alt")),
        parse(t("Topic3_Section6_imgsAlt[1][2].alt")),
      ],
      [
        parse(t("Topic3_Section6_imgsAlt[2][0].alt")),
        parse(t("Topic3_Section6_imgsAlt[2][1].alt")),
        parse(t("Topic3_Section6_imgsAlt[2][2].alt")),
      ],
      ["", "", ""],
    ],
    graphs: { en: recordedData, fr: recordedData_fr },
    msgs: [
      ["", "", ""],
      [
        parse(t("Topic3_Section6_msgs.[1][0]")),
        parse(t("Topic3_Section6_msgs.[1][1]")),
        parse(t("Topic3_Section6_msgs.[1][2]")),
      ],
      [
        parse(t("Topic3_Section6_msgs.[2][0]")),
        parse(t("Topic3_Section6_msgs.[2][1]")),
        parse(t("Topic3_Section6_msgs.[2][2]")),
      ],
    ],
  }
  // ============================================================================================
  const [part, setPart] = useState(0)
  const [msgType, setMsgType] = useState("")
  const [msgContent, setMsgContent] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  // ============================================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic3_Section6_pageTitle"))} />
      {/* <MyBreadcrumb topicTitle="Topic 1: Exponential Growth" topicLink="" /> */}
      <MyBreadcrumb
        topicLink="epopulationgrowth"
        topicTitle={parse(t("Topic3_Section6_breadcrumb[0]"))}
        currentTitle={pageContent.breadcrumb[part]}
      />

      <div className="homeContent">
        <Row>
          <Col sm="12">
            <TopHeader
              title={pageContent.title[part]}
              content={pageContent.topText[part]}
            />
          </Col>
        </Row>

        <div>
          <Row>
            <Col sm="12" hidden={part != 0}>
              <center>
                <div style={{ maxWidth: `620px` }}>
                  <img
                    src={language == "en" ? logisticModel : logisticModel_fr}
                    alt={changeGraph ? "recordedData" : "logisticModel"}
                    className="roundMe"
                    hidden={changeGraph}
                    // style={{ maxWidth: `290px`, marginBottom: `1.45rem` }}
                  />
                  <img
                    src={language == "en" ? recordedData : recordedData_fr}
                    alt={changeGraph ? "recordedData" : "logisticModel"}
                    className="roundMe"
                    hidden={changeGraph == false}
                    // style={{ maxWidth: `290px`, marginBottom: `1.45rem` }}
                  />
                  <p className="smText alignLeft">
                    {changeGraph
                      ? parse(t("Topic3_Section6_imgCaption2"))
                      : pageContent.imageText[part]}
                  </p>
                </div>
              </center>
            </Col>
          </Row>
          <Row hidden={part != 3}>
            <Col sm="6" md="8">
              {pageContent.middleTexts[part]}
            </Col>
            <Col sm="6" md="4">
              <img src={elephant} alt="" />
            </Col>
          </Row>

          <span hidden={(part == 0) | (part == 3)}>
            <Row>
              <Col sm="12" md="12">
                <p
                  className="borderMeGreen centerMe"
                  style={{ marginBottom: "30px" }}
                >
                  {pageContent.middleTexts[part]}
                </p>
              </Col>
              <Col sm="12" md="12">
                <Instructions content={pageContent.instructions[part]} />
              </Col>
            </Row>
          </span>
          <br hidden={part != 3} />
          {pageContent.bottomTexts[part]}

          <div hidden={part != 1 && part != 2}>
            <br />
            <Row>
              <Col sm="4">
                <img
                  src={
                    selectedQuestion == 0
                      ? pageContent.imgsSel[part][0]
                      : pageContent.imgs[part][0]
                  }
                  alt={selectedQuestion == 0 ? "" : ""}
                  className={
                    nextBtnEnabled == false ? "roundMe cursorPoint" : "roundMe"
                  }
                  onClick={() => {
                    if (nextBtnEnabled == false) {
                      setSelectedQuestion(0)
                      setMsgType("error")
                      setMsgContent(pageContent.msgs[part][0])
                      setShowMsg(true)
                    }
                  }}
                />
              </Col>
              <Col sm="4">
                <img
                  src={
                    selectedQuestion == 1
                      ? pageContent.imgsSel[part][1]
                      : pageContent.imgs[part][1]
                  }
                  alt={selectedQuestion == 1 ? "" : ""}
                  className={
                    nextBtnEnabled == false ? "roundMe cursorPoint" : "roundMe"
                  }
                  onClick={() => {
                    if (nextBtnEnabled == false) {
                      setSelectedQuestion(1)
                      setMsgType("success")
                      setMsgContent(pageContent.msgs[part][1])
                      setShowMsg(true)
                      setNextBtnEnabled(true)
                    }
                  }}
                />
              </Col>
              <Col sm="4">
                <img
                  src={
                    selectedQuestion == 2
                      ? pageContent.imgsSel[part][2]
                      : pageContent.imgs[part][2]
                  }
                  alt={selectedQuestion == 2 ? "" : ""}
                  className={
                    nextBtnEnabled == false ? "roundMe cursorPoint" : "roundMe"
                  }
                  onClick={() => {
                    if (nextBtnEnabled == false) {
                      setSelectedQuestion(2)
                      setMsgType("error")
                      setMsgContent(pageContent.msgs[part][2])
                      setShowMsg(true)
                    }
                  }}
                />
              </Col>
            </Row>
            <center>
              <p className="smText">
                {part == 1
                  ? parse(t("Topic3_Section6_imgChoices.caption"))
                  : parse(t("Topic3_Section6_imgChoices2.caption"))}
              </p>
            </center>
            <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
          </div>
        </div>
        <div hidden={part == 3}>
          <br hidden={part != 0} />
          <hr />
        </div>

        <MyModal
          size="lg"
          className="rModal"
          show={modalShow}
          onHide={() => setModalShow(false)}
          centered
        />

        <Row hidden={part == 3}>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              prevName={parse(t("Previous"))}
              btnLinkPrev={
                part == 0 ? "/epopulationgrowth/section5" : "#myHeader"
              }
              onClickPrev={() => {
                setSelectedQuestion(null)
                setMsgType("")
                setMsgContent("")
                setShowMsg(false)
                switch (part) {
                  case 1:
                    setNextBtnEnabled(true)
                    setPart(part - 1)
                    break
                  case 2:
                    setNextBtnEnabled(false)
                    setPart(part - 1)
                    break
                  default:
                    return null
                    break
                }
              }}
              btnLinkNext={part == 3 ? "/" : "#myHeader"}
              nextName={part == 3 ? parse(t("Next Topic")) : parse(t("Next"))}
              onClickNext={() => {
                setMsgType("")
                setMsgContent("")
                setShowMsg(false)
                setSelectedQuestion(null)
                // setCurrentYear(currentYear + 1)
                if (part == 0) {
                  setPart(part + 1)
                  setNextBtnEnabled(false)
                }
                if (part == 1) {
                  setPart(part + 1)
                  setNextBtnEnabled(false)
                }
                if (part == 2) {
                  setPart(part + 1)
                  setNextBtnEnabled(true)
                }
              }}
              disabled={!nextBtnEnabled}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default EPopGrowthSection6
